import { lazy } from 'react';
import { Route } from 'react-router-dom';

const MyUnits = lazy(() => import('./list/MyUnits'));
const TeamsMyUnit = lazy(() => import('./detail/teams/TeamsMyUnit'));
const MembersMyUnit = lazy(() => import('./detail/members/MembersMyUnit'));

export const myUnitsRoutes = (
  <>
    <Route path="/my-units" element={<MyUnits />} />
    <Route path="/my-units/:id/members" element={<MembersMyUnit />} />
    <Route path="/my-units/:id/teams" element={<TeamsMyUnit />} />
  </>
);
