import { TFunction } from 'i18next';
import {
  TbAlertCircle,
  TbBasket,
  TbBeach,
  TbBuildingCommunity,
  TbCalendarEvent,
  TbCircles,
  TbNotebook
} from 'react-icons/tb';

type ForecastSpecialEffort = {
  id: number;
  key: string;
  descriptionKey: string;
};

export type SpecialEffortItem = Omit<ForecastSpecialEffort, 'key'> & {
  name: string;
};

export const specialEffortIconsMap: Map<number, any> = new Map([
  [1, TbBeach],
  [2, TbBuildingCommunity],
  [3, TbBasket],
  [4, TbCalendarEvent],
  [5, TbAlertCircle],
  [6, TbNotebook],
  [7, TbCircles]
]);

export enum SpecialEffortValue {
  VACATION = 1,
  SICK_OR_LEAVE = 2,
  SALES_SUPPORT = 3,
  BANK_HOLIDAY = 4,
  IDLE = 5,
  OTHER = 6,
  CHAPTER = 7
}

const FORECAST_MANUALLY_ALLOWED_SPECIAL_EFFORT: readonly ForecastSpecialEffort[] = Object.freeze([
  {
    id: SpecialEffortValue.SALES_SUPPORT,
    key: 'forecast.special-effort.sales-support',
    descriptionKey: 'forecast.legend.info.sales'
  },
  {
    id: SpecialEffortValue.IDLE,
    key: 'forecast.special-effort.idle',
    descriptionKey: 'forecast.legend.info.idle'
  },
  {
    id: SpecialEffortValue.OTHER,
    key: 'forecast.special-effort.other',
    descriptionKey: 'forecast.legend.info.other'
  },
  {
    id: SpecialEffortValue.CHAPTER,
    key: 'forecast.special-effort.chapter',
    descriptionKey: 'forecast.legend.info.chapter'
  }
]);

const ALL_SPECIAL_EFFORTS: readonly ForecastSpecialEffort[] = Object.freeze([
  {
    id: SpecialEffortValue.VACATION,
    key: 'forecast.special-effort.vacation',
    descriptionKey: 'forecast.legend.info.vacation'
  },
  {
    id: SpecialEffortValue.SICK_OR_LEAVE,
    key: 'forecast.special-effort.sick-or-leave',
    descriptionKey: 'forecast.legend.info.sick'
  },
  {
    id: SpecialEffortValue.BANK_HOLIDAY,
    key: 'forecast.special-effort.bank-holiday',
    descriptionKey: 'forecast.legend.info.bank'
  },
  ...FORECAST_MANUALLY_ALLOWED_SPECIAL_EFFORT
]);

export function getManualForecastSpecialEffortItems(t?: TFunction): SpecialEffortItem[] {
  const list: SpecialEffortItem[] = FORECAST_MANUALLY_ALLOWED_SPECIAL_EFFORT.map((effort) => ({
    ...effort,
    key: undefined,
    name: t(effort.key)
  }));

  return list.sort((a, b) => a.id - b.id);
}

export function getSpecialEffortIcon(specialEffort: number): any | null {
  if (!specialEffortIconsMap.has(specialEffort)) {
    return null;
  }

  return specialEffortIconsMap.get(specialEffort);
}

export const canSpecialEffortBeManuallyUpdated = (specialEffort: number) =>
  FORECAST_MANUALLY_ALLOWED_SPECIAL_EFFORT.some((item) => item.id === specialEffort);

export const getSpecialEffortNameKey = (specialEffort: number) =>
  ALL_SPECIAL_EFFORTS.find(({ id }) => id === specialEffort)?.key;
