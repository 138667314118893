export enum TableAction {
  CreateEdit = 'createEdit',
  Edit = 'edit',
  Delete = 'delete',
  ToggleActive = 'toggleactive',
  MSTeams = 'msteams',
  ToDetail = 'toDetail',
  Unarchive = 'unarchive',
  UnitActive = 'unitActive'
}

export type TableActionHandler<T = any> = (row: T) => void;
